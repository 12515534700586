<template>
  <div class="form-field" :class="{ 'is--focused': focused, 'is--filled': !!modelValue }" :style="fieldStyle">
    <label :for="`${id}`">{{ label }} <span v-if="isRequired" class="form-field__required">*</span></label>
    <input
        class="form-control"
        :id="`${id}`"
        :type="type"
        v-bind="$attrs"
        :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)"
        @focus="focus"
        @blur="blur"
    >
  </div>
</template>

<script>
export default {
  name: 'InputField',
  inheritAttrs: false,
  data() {
    return {
      focused: false,
    }
  },
  props: {
    id: String,
    label: String,
    type: String,
    fieldStyle: String,
    modelValue: [String, Number]
  },
  computed: {
    isRequired() {
      return Object.keys(this.$attrs).includes('required');
    }
  },
  methods: {
    focus() {
      this.focused = true;
    },
    blur() {
      this.focused = false;
    }
  }
}
</script>

<style lang="scss" scoped>
label {
    left: 0;
    top: 50%;
    position: absolute;
    transform: translateY(-50%);
    transform-origin: 0 0;
    transition: all .15s ease-in-out;
}

input {
    background: transparent;
    border: none;
    font-size: 16px;
    outline: none;
    width: 100%;
    max-width: 100%;
    transition: all .15s ease-in-out;

    &:invalid.is--filled + label {
        color: red;
    }
}

.form-field {
    margin: 0 10px;
    position: relative;
    color: #082641;
    width: 100%;

    label {
        padding: 0 10px;
    }

    input {
        border: none;
        margin: 20px 0 12px 0;
        opacity: 0;
        padding: 0 10px;
    }

    + .form-field::before {
        content: "";
        clear: both;
        display: block;
        border-top: 1px solid #EEEEEF;
        height: 1px;
    }

    &__required {
      color: rgb(204, 43, 3);
    }
}

// Focused / filled
.is--focused,
.is--filled {
    label {
        font-size: 10px;
        top: 14px;
        font-weight: bold;
    }

    input {
      opacity: 1;
    }
}
</style>

