<template>
  <div id="success" class="success" role="alert">
    <header class="success__header">
      <div class="success__icon">
        <svg class="icon" width="84px" height="84px" viewBox="0 0 84 84" version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink">
          <circle class="icon__stroke" cx="42" cy="42" r="40" stroke-linecap="round" stroke-width="4" stroke="#000" fill="none"/>
          <path class="icon__stroke" stroke-linecap="round" stroke-linejoin="round" d="M23.375 42.5488281 36.8840688 56.0578969 64.891932 28.0500338" stroke-width="4" stroke="#000" fill="none"/>
        </svg>
      </div>
      <div class="success__content">
        <div class="success__title">Thank You!</div>
        <div class="success__amt">${{ charge.amount }}</div>
      </div>
    </header>
    <main class="success__main">
      <div class="kv__list">
        <div class="kv">
          <div class="kv__k">Transaction ID</div>
          <div class="kv__v"><code>{{ charge.key }}</code></div>
        </div>
        <div class="kv">
          <div class="kv__k">Email</div>
          <div class="kv__v">{{ charge.email }}</div>
        </div>
        <div class="kv">
          <div class="kv__k">Company</div>
          <div class="kv__v">{{ charge.company }}</div>
        </div>
        <div v-if="charge.description" class="kv">
          <div class="kv__k">Memo</div>
          <div class="kv__v">{{ charge.description }}</div>
        </div>
      </div>
      <ResetButton class="btn" @click="reset" :disabled="false">All Done</ResetButton>
    </main>
  </div>
</template>

<script>
import ResetButton from './Button.vue';

export default {
  name: 'Success',
  props: {
    charge: Object
  },
  components: {
    ResetButton,
  },
  methods: {
    reset() {
      this.$emit('reset');
    }
  }
}
</script>

<style lang="scss" scoped>
.success {
  background: rgba(255,255,255,.92);
  border-radius: 6px;
  margin: 20px 0;
  overflow: hidden;

  &__header {
    align-items: center;
    background: #fff;
    box-shadow: 0 0 3px rgba(0,0,0,0.2);
    display: flex;
    justify-content: center;
    padding: 20px;
  }

  &__content {
    padding-left: 30px;
  }

  &__title {
    font-weight: bold;
    font-size: 24px;
  }

  &__amt {
    font-weight: bold;
    font-size: 20px;
  }

  &__main {
    padding: 20px;
  }
}

.icon {
  display: block;
  width: 66px;

  &__stroke {
    stroke: #63A84B;
  }
}

.kv {
  margin-bottom: 10px;

  &__k {
    color: #777;
    font-size: 11px;
    line-height: 1.2;
    text-transform: uppercase;
  }
  &__v {
    color: #333;
  }
}

.btn {
  margin-top: 20px;
}
</style>
