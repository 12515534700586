<template>
  <div class="fieldset" :class="className">
      <slot />
  </div>
</template>

<script>
export default {
  name: 'Fieldset',
  props: {
    className: String
  }
}
</script>

<style lang="scss" scoped>
.fieldset {
    background: #FAFAFA;
    border: none;
    box-shadow: 0 1px 6px -2px rgba(45,39,41,0.50);
    border-radius: 5px;
    margin: 20px 0;
    overflow: hidden;
    padding: 0;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
</style>
