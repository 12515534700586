<template>
    <div class="spinner" :style="styles" />
</template>

<script>
export default {
  props: {
    size: {
      default: '26px',
      type: String
    },
    stroke: {
      default: '2px',
      type: String
    },
    rgb: {
      default: '255,255,255',
      type: String
    }
  },
  computed: {
    styles() {
      return {
        width: this.size,
        height: this.size,
        border: `${this.stroke} solid rgba(${this.rgb}, .4)`,
        'border-left-color': `rgba(${this.rgb}, 1)`
      }
    }
  }
}
</script>


<style lang="scss" scoped>
.spinner,
.spinner:after {
  border-radius: 50%;
}
.spinner {
  margin: 0 auto;
  position: relative;
  text-indent: -9999em;
  transform: translateZ(0);
  animation: animateSpinner 1s infinite linear;
}
@keyframes animateSpinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
