<template>
  <div :id="id" class="epay-container" ref="container"></div>
</template>

<script>
import { Client } from '../services/usaepay.service';

const style = {
  base: {
    background: 'transparent',
    'font-family': "'Public Sans', sans-serif",
    'font-size': '16px',
    margin: '16px 0',
    'padding': '0'
  },
  invalid: {
    color: '#BF2525'
  },
  valid: {
    color: '#63A84B'
  }
};

export default {
  name: 'InputEPay',
  inheritAttrs: false,

  emits: ['card-ready'],

  data() {
    return {
      id: '',
      paymentCard: null
    }
  },

  beforeMount() {
    // Generate a unique ID because EPay expects one
    this.id = 'input-epay' + Date.now().toString();
  },

  mounted() {
    this.paymentCard = Client.createPaymentCardEntry();
    this.paymentCard.generateHTML(style);
    this.paymentCard.addHTML(this.id);
    this.$emit('card-ready', this.paymentCard);
  }
}
</script>

<style scoped>
.epay-container {
  height: 52px;
  margin: 0 10px;
  width: 100%;
}
</style>
